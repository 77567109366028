import React, { FC, useEffect, useMemo, useState } from 'react';
import Container from 'react-bootstrap/Container';
import RecommendedProducts from 'components/RecommendedProducts';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { IRecommendedProductsProps } from 'components/RecommendedProducts/model';
import QuizProgress from './components/QuizProgress';
import QuizResult from './components/QuizResult';
import QuizSlide from './components/QuizSlide';
import WhyBanner from './components/WhyBanner';
import './Quiz.scss';

const Quiz: FC<QuizTypes.IQuiz> = (props) => {
  const {
    ariaNext,
    ariaPrev,
    progressBar,
    stepPrefix,
    progressBarTitle,
    quizSlides,
    prevButton,
    nextButton,
    ariaResult,
    resultButton,
    nodes: products,
    quizResult,
    whyBanner,
    recommendedProducts,
    quizDisclaimer,
    pageName,
    allProducts,
    lang,
  } = props;
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [filterResult, setFilterResult] = useState([]);
  const [gavisconProducts, setGavisconProducts] = useState<IRecommendedProductsProps | null>(null);
  const [gavisconProductsLinks, setGavisconProductsLinks] = useState<string[] | null>(null);
  const [tagsCollection, setTagsCollection] = useState<(string | null)[]>([]);
  const [currentSlide, setCurrentSlide] = useState<QuizTypes.TQuizSlide | undefined>(
    quizSlides.find((slide) => slide.content.properties.step === 1)
  );
  const recommendedProductsItems: ProductPageTypes.IProductCard[] = useMemo(() => {
    if (!gavisconProductsLinks) return [];
    return allProducts.nodes.filter((product) => gavisconProductsLinks.includes(product.link));
  }, [gavisconProductsLinks]);
  useEffect(() => {
    if (filterResult && filterResult.length) {
      const resultQuizRecommendedProductsExist = (filterResult[0] as any).quizRecommendProducts;
      if (resultQuizRecommendedProductsExist) {
        const resultQuizRecommendedProducts = (filterResult[0] as any).quizRecommendProducts[0];
        const recommendedProductsProps: IRecommendedProductsProps = {
          productLinks: resultQuizRecommendedProducts.properties.list.map((product) => {
            return {
              properties: {
                link: [{ url: product.properties.link[0].url }],
                hideVariant: product.properties.hideVariant,
              },
            };
          }),
          products: { nodes: recommendedProductsItems },
          title: recommendedProducts?.title,
          isFamily: recommendedProducts?.isFamily,
          ariaAllProducts: recommendedProducts?.ariaAllProducts,
          linkAllProducts: recommendedProducts?.linkAllProducts,
          isArticlePage: false,
        };
        setGavisconProducts(recommendedProductsProps);
      }
    }
  }, [filterResult]);
  const handleNextSlide = (url: string) => {
    const nextSlide = quizSlides.find((slide) => slide.content.url === url);
    if (nextSlide) {
      setCurrentSlide(nextSlide);
      setCurrentStep(nextSlide.content.properties.step);
    }
  };
  const handlePrevSlide = (url: string) => {
    const prevSlide = quizSlides.find((slide) => slide.content.url === url);
    if (prevSlide) {
      setTagsCollection((prevTags) => {
        const newTags = [...prevTags];
        newTags[currentStep - 1] = null;

        return newTags;
      });
      setCurrentSlide(prevSlide);
      setCurrentStep(prevSlide.content.properties.step);
    }
  };
  const handleOptionSelected = (tags: string, step: number) => {
    setTagsCollection((prevTags) => {
      const newTags = [...prevTags];
      newTags[step] = tags;

      return newTags;
    });
  };
  const handleReset = () => {
    setShowResult(false);
    setTagsCollection([]);
    setGavisconProductsLinks(null);
    setGavisconProducts(null);
    setCurrentStep(1);
    setFilterResult([]);
    setCurrentSlide(quizSlides.find((slide) => slide.content.properties.step === 1));

    window.scrollTo(0, 0);
  };
  const handleSeeRsult = () => {
    const result = tagsCollection.filter(Boolean).reduce((list, tag) => {
      return list.filter((product) => Boolean(product.tags.find((t) => t.id === tag)));
    }, products);
    setFilterResult(result);
    if (result && result[0].quizRecommendProducts) {
      const productLinks: string[] = [];
      result[0]?.quizRecommendProducts[0]?.properties.list.forEach((product) => {
        productLinks.push(product.properties.link[0].url);
      });
      setGavisconProductsLinks(productLinks);
    }
    setShowResult(true);
  };

  return (
    <>
      <div className="quiz-section">
        <Container fluid>
          {quizDisclaimer ? (
            <DangerouslySetInnerHtml
              element="div"
              className="quiz-section__disclaimer"
              html={quizDisclaimer}
            />
          ) : null}
          {!showResult ? (
            <div className="quiz-section__grid">
              <QuizProgress {...{ currentStep, progressBar, progressBarTitle, stepPrefix }} />
              <div className="quiz-section__main-column">
                {quizSlides?.length
                  ? quizSlides.map(({ content: { properties, id, url } }) => (
                      <QuizSlide
                        key={id}
                        {...{
                          handleNextSlide,
                          handlePrevSlide,
                          handleOptionSelected,
                          currentSlide,
                          url,
                          id,
                          prevButton,
                          nextButton,
                          ariaResult,
                          resultButton,
                          ariaNext,
                          ariaPrev,
                          handleSeeRsult,
                          ...properties,
                        }}
                      />
                    ))
                  : null}
              </div>
            </div>
          ) : (
            <>
              <QuizResult {...{ quizResult, filterResult, handleReset, pageName }} />
              {lang?.includes('au') && gavisconProducts?.products ? (
                <RecommendedProducts {...gavisconProducts} />
              ) : recommendedProducts?.products ? (
                <RecommendedProducts {...recommendedProducts} />
              ) : null}
            </>
          )}
        </Container>
      </div>
      {showResult && whyBanner?.length ? <WhyBanner {...{ whyBanner }} /> : null}
    </>
  );
};

export default Quiz;
